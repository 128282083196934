// @value is a css import that allows us to use parent's css classes in child components
@value item__view_mode__list, item__view_mode__grid, item from "../FdocItem.module.scss";

.item__content {
  padding: 15px;
  position: relative;

  &:has(.item__content__image) {
    padding: 0;
  }
}

.item__content__notepad {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  max-height: 74px;
}

.item__content__text {
  // max-height can be the same as width of self
  max-height: 74px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 3px;
  margin-top: -3px;

  > span {
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;

    font-weight: 400;
    font-size: 13px;
    line-height: 20px;

    background: #e1e5ff;

    // box-shadow to connect the background from line to line
    // horizontal offset, vertical offset, blur radius, spread radius, color
    box-shadow: 0px 1.2px 0px 0px #e1e5ff, 0px -1.2px 0px 0px #e1e5ff, 5px -1.2px 0px 0px #e1e5ff,
      5px 1.2px 0px 0px #e1e5ff, -5px -1.2px 0px 0px #e1e5ff, -5px 1.2px 0px 0px #e1e5ff;
  }
}

.item__content__image {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  img {
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
  }
}

.item__content__page {
  padding: 15px;

  > img {
    object-fit: contain;
    border-radius: 6px;

    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
  }
}

// parent is in list view
.item__view_mode__list {
  .item__content {
    padding: 0;

    min-width: 115px;
    max-width: 43%;

    padding-right: 23px;
  }

  .item__content__text {
    max-height: 42px;
    font-size: 12px;
  }

  .item__content__image {
    max-width: 73px;
    border-radius: 4px;
    overflow: hidden;
  }

  .item__content__page {
    img {
      max-height: 60px;
      max-width: 100px;
      border: 1px solid #dfdfdf;
      border-radius: 4px;
    }
  }
}

.item__view_mode__grid {
  .item__content {
    &:not(:last-child):not(:has(.item__content__image)) {
      &::after {
        content: '';
        height: 1px;
        background-color: #d9d9d9;

        position: absolute;
        bottom: 0;
        left: 10px;
        right: 10px;
      }
    }
  }
}

.stored_file {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}
