.notifications {
  padding: 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  flex-grow: 1;
  overflow-y: auto;
}

.empty_state {
  display: flex;
  flex-grow: 1;
  width: 100%;
  height: 100%;

  align-items: center;
  flex-direction: column;

  > h1 {
    font-weight: 700;
    font-size: 26px;
    line-height: 24px;

    margin-bottom: 38px;
  }

  > svg {
    margin-bottom: 42px;
  }

  > a {
    text-decoration: underline;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
  }

  &::before,
  &::after {
    content: '';
    flex: 1;
  }
}
