// @value is a css import that allows us to use parent's css classes in child components
@value item__view_mode__list, item__view_mode__grid, item, item--hover, disableHover from "../FdocItem.module.scss";

.item__header {
  display: flex;
  align-items: center;

  padding: 15px 15px 0 15px;

  cursor: pointer;

  svg {
    flex-shrink: 0;
  }
}

.item__hover__header {
  z-index: 10;

  display: none;
  justify-content: space-between;
  align-items: center;

  cursor: pointer;

  h2 {
    padding-right: 13px;
  }

  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  margin: 3px;
  padding: 8px;

  color: white;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 8px;

  svg {
    flex-shrink: 0;
  }

  &:hover {
    background: black;
  }
}

.item__header,
.item__hover__header {
  h2 {
    font-weight: 400;
    font-size: 16px;
    line-height: 19.38px;

    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;

    white-space: nowrap;
  }
}

.item__header_url {
  padding: 10px 15px 0 15px;

  &:last-child {
    padding-bottom: 15px;
  }
}

.item__header_url__text {
  cursor: pointer;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  background: #eceff8;

  padding: 1px 7px;
  border-radius: 9px;

  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  max-width: 100%;

  span {
    max-width: 100%;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.item:not(.disableHover) {
  &:hover,
  &.item--hover {
    .item__hover__header {
      display: flex;
    }
  }
}

// parent is in list view
.item__view_mode__list {
  .item__header {
    gap: 10px;
    padding: 0;

    width: 335px;
    flex-shrink: 0;
    flex-grow: 0;
    padding-right: 20px;
    padding-left: 5px;

    > svg {
      min-width: 14px;
    }

    h2 {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }

    .item__hover__header__favicon {
      margin-right: 4px;
      width: 19px;
      height: 19px;
    }
  }

  .item__header_url {
    padding: 0 33px 0 0px;
  }

  .item__hover__header__webnote_title {
    background: rgba(0, 0, 0, 0.04);
    border: 1px solid rgba(0, 0, 0, 0.04);
    border-radius: 8px;

    padding: 7px 8px 8px 11px;

    flex-grow: 1;

    .item__hover__header__favicon {
      margin-right: 6px;
    }

    > h2 {
      padding-right: 15px;
    }

    > svg {
      margin-left: auto;
      display: block;

      height: auto;
    }

    > img {
      width: auto;
      height: 19px;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.7);
      color: white;
    }
  }

  .item__header_url__text {
    max-width: 198px;
  }
}

.item__hover__header__fade {
  position: absolute;
  inset: -1px;
  border-radius: 10px;
  z-index: 5;
  display: none;

  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0px 0px 0px 6px rgba(255, 255, 255, 0.3);
}

.item__hover__header__fade__visible {
  display: block;
}

.item__hover__header__title {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;

  > svg {
    display: none;
  }
}

.item__hover__header__favicon {
  flex-shrink: 0;
  width: 19px;
  height: 19px;
  margin-right: 4px;
}

.item__view_mode__grid {
  .item__hover__header__webnote_title {
    color: #666;
  }
}
