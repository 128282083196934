.notification {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 100%;
  flex-direction: column;
  gap: 10px;
  font-family: Inter;
  font-size: 13px;
  border-bottom: thin solid rgba(227, 227, 228, 1);
  padding: 25px 10px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
  gap: 10px;
  font-family: Inter;
  font-size: 13px;
}

.header_left {
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: row;
  gap: 10px;
  font-family: Inter;
  font-size: 13px;
}

.comment {
  margin-left: 60px;
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: row;
  gap: 10px;
  font-family: Inter;
  font-size: 13px;
}

.bold {
  font-weight: 600;
}

.underlined {
  text-decoration: underline;
}

.date {
  width: 104.25px;
  height: 13px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;

  color: rgba(0, 0, 0, 0.5);

  flex: none;
  order: 1;
  flex-grow: 0;
}
.main_row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  align-items: center;
}
.dot {
  background-color: red;
  border-radius: 50%;
  height: 10px;
  min-width: 10px;
}
