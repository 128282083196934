$mobile-width: 768px;

.authenticator {
  --amplify-components-button-link-color: #0029ff;
  --amplify-components-button-primary-background-color: #0029ff;
  --amplify-components-button-primary-hover-background-color: #1215b3;
  --amplify-components-button-primary-focus-background-color: #1215b3;
  --amplify-components-button-primary-active-background-color: #170c9c;
  --amplify-components-button-border-radius: 13px;
  --amplify-components-flex-gap: 10px;

  --amplify-components-button-link-hover-background-color: transparent;
  --amplify-components-button-link-focus-background-color: transparent;
  --amplify-components-button-link-active-background-color: transparent;
  --amplify-components-button-link-hover-border-color: transparent;
  --amplify-components-button-link-focus-border-color: transparent;
  --amplify-components-button-link-active-border-color: transparent;
  --amplify-components-button-link-hover-color: #1215b3;
  --amplify-components-button-link-focus-color: #1215b3;

  --svg-size: 70px;
  --svg-size-mobile: 50px;
  --header-padding: 24px;

  width: 100%;
  height: 100%;

  .authenticator__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
    position: relative;
    margin-bottom: auto;

    width: 100%;
    padding: var(--header-padding);

    svg {
      width: var(--svg-size);
      height: var(--svg-size);

      @media (max-width: $mobile-width) {
        width: var(--svg-size-mobile);
        height: var(--svg-size-mobile);
      }
    }

    @media (max-width: $mobile-width) {
      align-items: flex-start;
      flex-direction: column;
      gap: 27px;
      padding: 16px;
      // margin-bottom: 35px;
      margin-bottom: 0;
    }

    button {
      display: flex;
      align-items: center;
      font-size: 15px;
      font-weight: 600;
      color: #0029ff;

      svg {
        transform: rotate(90deg);
        width: 14px;
        height: 14px;
        margin-right: 8px;
      }

      @media (max-width: $mobile-width) {
        margin-left: -2px;
        margin-bottom: -26px;
        bottom: 0;
      }
    }
  }

  [data-amplify-container] {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;
    overflow-y: auto;

    [data-amplify-router] {
      padding: 16px;
      padding-bottom: 0;
      width: 100%;
      max-width: 430px;
      border: none;
      box-shadow: none;

      // minus the Height of svg (70px) + padding (24*2)
      margin-top: -118px;

      place-self: center;

      [data-amplify-form] {
        padding: 0;
      }

      [data-amplify-footer] {
        margin-top: 57px;
        padding-bottom: 10px;
        font-size: 15px;

        @media (max-width: $mobile-width) {
          margin-top: 37px;
        }
      }

      @media (max-width: $mobile-width) {
        margin-top: 0;
      }

      [data-amplify-authenticator-resetpassword] {
        button.amplify-button.amplify-field-group__control[type='button'] {
          display: none;
        }
      }
    }
  }

  .amplify-button[type='submit'],
  .amplify-button.federated-sign-in-button {
    border: 1px solid black;
    border-radius: 13px;
    padding-top: 12px;
    padding-bottom: 12px;
    height: 50px;
  }

  .authenticator__title,
  .amplify-heading {
    font-size: 34px;
    line-height: 41px;
    font-weight: 700;
    margin-bottom: 30px;

    @media (max-width: $mobile-width) {
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 20px;
    }
  }

  .amplify-divider.amplify-divider--label {
    margin-top: 9px;
    margin-bottom: 6px;
    border: none;
    font-weight: 600;
    font-size: 16px;
  }

  .amplify-input {
    border: 1px solid black;
    border-radius: 13px;
    padding: 12px 16px;

    &::placeholder {
      font-size: 15px;
      color: #5f5f5f;
    }
  }

  .amplify-field-group {
    .amplify-field-group__outer-end > * {
      border-top-right-radius: 13px;
      border-bottom-right-radius: 13px;
      border: 1px solid black;
      border-left: none;
    }
  }

  .authenticator__footer {
    font-size: 15px;
    width: 100%;
    text-align: center;
    margin-bottom: auto;

    a {
      color: #0029ff;

      &:hover {
        color: #1215b3;
      }
    }
  }

  .authenticator__note_footer {
    font-size: 15px;
    color: #5f5f5f;
  }

  .amplify-tabs {
    display: none;
  }

  .amplify-button.amplify-field-group__control {
    font-size: 15px;
  }
}
