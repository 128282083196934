.button {
  padding: 10px 16px;

  color: white;
  background: #2a37ee;
  border: none;
  white-space: nowrap;

  border-radius: 10px;

  font-weight: 500;
  font-size: 12px;
  line-height: 15px;

  display: flex;
  align-items: center;
  gap: 14.5px;

  cursor: pointer;

  > svg {
    height: 16px;
    width: auto;
  }

  > kbd {
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    padding: 2px 3px;
    border-radius: 4px;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    color: rgba(255, 255, 255, 0.8);
    background: #4c57f1;
  }

  &:hover {
    background: #0028af;
  }

  &.button_large {
    padding: 9px 23px;

    border-radius: 8px;

    font-size: 16px;
    line-height: 30px;

    > svg {
      margin-right: 10px;
    }
  }

  &.button_small {
    padding: 0px 8px;
    font-size: 14px;
    line-height: 30px;

    border-radius: 6px;
  }

  &.button_medium-large {
    padding: 6px 68px;

    font-size: 14px;
    line-height: 30px;
  }

  &.button_light-primary {
    background: #3300ff;
    border: 1px solid rgba(255, 255, 255, 0.15);

    &:hover {
      background: #1a00cc;
    }
  }

  &.button_red {
    background: #c72727;

    &:hover {
      background: #a61f1f;
    }
  }

  &.button_light-red {
    background: #ca3434;
    border: 1px solid rgba(255, 255, 255, 0.15);

    &:hover {
      background: #a61f1f;
    }
  }

  &.button_violet {
    background: #8f92bc;

    &:hover {
      background: #6f72ac;
    }
  }

  &.button_transparent {
    background: transparent;
    border: 1px solid black;
    color: black;

    > kbd {
      background: #f0f0f0;
      color: rgba(0, 0, 0, 0.8);
    }

    &:hover {
      background: rgba(42, 55, 238, 0.1);
      border: 1px solid black;
    }
  }

  &.button_transparent-white {
    background: rgba(0, 0, 0, 0.2);
    border: 1px solid white;
    color: white;

    &:hover {
      background: rgba(0, 0, 0, 0.3);
      border: 1px solid white;
    }
  }

  &:disabled {
    background: rgba(111, 111, 111, 0.5);

    > kbd {
      background: rgba(111, 111, 111, 0.5);
    }

    &:hover {
      background: rgba(111, 111, 111, 0.5);
    }

    cursor: not-allowed;
  }

  &.button_eletric-ultramarine {
    background: rgba(51, 0, 255, 0.1);
    border: 1px solid rgba(51, 0, 255, 0.3);
    color: #3300ff;

    &:hover {
      background: rgba(51, 0, 255, 0.2);
      border: 1px solid rgba(51, 0, 255, 0.4);
    }
  }

  &.button_black {
    background: #000000;
    color: #ffffff;

    &:hover {
      background: #1a1a1a;
    }
  }

  &.button_xl {
    padding: 20px 25px;
    font-weight: 800;
    font-size: 28px;
    line-height: 34px;
    border-radius: 13px;

    gap: 22px;
  }
}
