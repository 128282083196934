// @value is a css import that allows us to use parent's css classes in child components
@value item__view_mode__list, item from "../FdocItem.module.scss";

.item__skeleton {
  display: flex;
  flex-direction: column;
}

.item__skeleton__text {
  padding: 10px 10px 0 10px;

  width: 100%;
  display: flex;
  flex-direction: column;

  gap: 2px;

  &:last-child {
    padding-bottom: 10px;
  }
}

.item__skeleton__page {
  padding: 10px 10px 0 10px;

  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 10px;

  &:last-child {
    padding-bottom: 10px;
  }
}

.item__skeleton__comments {
  padding: 10px;

  display: flex;
  align-items: center;

  gap: 8px;
}

.item__skeleton__comments__users {
  display: flex;
  align-items: center;

  gap: 2px;
}

// parent is in list view
.item__view_mode__list {
  .item__skeleton {
    flex-direction: row;
    width: 100%;

    align-items: center;
    padding: 0;
  }

  .item__skeleton__page {
    flex-direction: row;
    align-items: center;
    width: 325px;
    padding-right: 25px;
  }

  .item__skeleton__text {
    width: 40%;
  }
}
