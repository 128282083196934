.hover_item {
  z-index: 20;

  position: fixed;

  color: white;
  background-color: black;
  border: 1px solid #bdbdbd;

  max-width: 500px;
  padding: 8px 12px;

  font-size: 13.5px;
  line-height: 16.34px;

  border-radius: 10px;

  overflow: hidden;

  display: flex;
  flex-direction: column;
}

.hover_item__header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 7px;

  font-weight: 600;

  svg {
    margin-left: 10px;
  }
}

.hover_item__url {
  color: #d0d0d0;
  white-space: nowrap;

  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  font-weight: 500;

  margin: 0;
}
