.item__context_menu {
  display: flex;
  flex-direction: column;

  position: fixed;
  z-index: 50;

  white-space: nowrap;

  padding: 3px;
  border-radius: 12px;
  background-color: black;

  gap: 2px;

  overflow: hidden;
}

.item__context_menu__button {
  padding: 8px;
  color: white;

  cursor: pointer;

  background: none;
  border: 1px solid transparent;

  font-weight: 500;
  font-size: 13.5px;
  line-height: 16px;

  display: flex;
  align-items: center;

  border-radius: 9px;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.1);
  }

  &.item__context_menu__button_red {
    color: #ff0000;
  }
}
