.error {
  background: white;
  display: flex;
  flex-direction: column;
  padding: 46px 52px;
  width: 100%;
  height: 100%;

  > header {
    display: flex;
    align-items: center;
  }
}

.dashboard_button {
  display: inline-flex;
  align-items: center;

  background: rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(0, 0, 0, 0.04);
  border-radius: 20px;
  padding: 11px 16px;
  width: min-content;
  white-space: nowrap;

  font-weight: 500;
  font-size: 15px;
  line-height: 17px;

  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }
}

.spacer {
  flex: 1;
  width: 100%;
}

.error_content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;

  h1,
  h2,
  h3,
  h4,
  h5 {
    text-align: center;
    max-width: 500px;
    &:not(:last-child) {
      margin-bottom: 42px;
    }
  }

  h1 {
    font-weight: 800;
    font-size: 120px;
    line-height: 120px;
    color: #2b38f5;

    font-family: 'Manrope', sans-serif;
  }

  h4 {
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    color: #2b38f5;
  }

  p,
  a {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;

    margin: 0;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  a {
    text-decoration: underline;
  }

  pre {
    margin-top: 52px;
    padding: 4px 6px;
    max-height: 200px;
    overflow: auto;

    font-family: 'Anonymous Pro', monospace;
    background: rgba(43, 56, 245, 0.08);
    border: 1px solid rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;

    color: #2b38f5;
  }
}
