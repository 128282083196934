@import '../assets/scss/variables';

.tooltip {
  padding: 9px 10px;
  font-size: 15px;
  color: white;
  background-color: black;
  border-radius: 10px;
  border: 1px solid #bdbdbd;
  width: max-content;

  .tooltip__arrow {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: black;

    border-top: 1px solid #bdbdbd;
    border-left: 1px solid #bdbdbd;
  }

  &.error {
    background-color: #b02828;

    .tooltip__arrow {
      background-color: #b02828;
    }
  }

  @media (max-width: $mobile-width) {
    // Currently disabled because still needs some changes for the arrow.
    display: none;
  }
}
