@import url('https://fonts.googleapis.com/css2?family=Anonymous+Pro:ital,wght@0,400;0,700;1,400;1,700&family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Manrope:wght@800&display=swap&text=%E2%86%90|%E2%86%92');
@import url('https://fonts.googleapis.com/css2?family=Anonymous+Pro:ital,wght@0,400;0,700;1,400;1,700&family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Manrope:wght@800&display=swap');

html,
body {
  overscroll-behavior-x: none;
  -webkit-overflow-scrolling: auto;
}

html,
body {
  height: 100%;
  min-height: auto !important; // Fix for iOS and Android bottom and top bars
  overflow: hidden;
}

// make all elements by default unselectable
* {
  user-select: none;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --mobile-width: 768px;
}

.gradient-primary {
  background-color: black;
  background: linear-gradient(
      295.3deg,
      rgba(236, 12, 255, 0.2) 3.58%,
      rgba(0, 0, 0, 0.2) 44.51%,
      rgba(20, 0, 255, 0.2) 59.72%
    ),
    #000000;
}

@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(0.82);
    transform: scale(0.82);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  18% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  52% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  78% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  100% {
    -webkit-transform: scale(0.92);
    transform: scale(0.92);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@keyframes heartbeat {
  from {
    -webkit-transform: scale(0.82);
    transform: scale(0.82);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  18% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  52% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  78% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  100% {
    -webkit-transform: scale(0.92);
    transform: scale(0.92);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

.animate-heartbeat {
  -webkit-animation: heartbeat 0.8s ease-in-out infinite alternate;
  animation: heartbeat 0.8s ease-in-out infinite alternate;
}

/** These animations need a lot of work to make them prettier */

@keyframes microphone-animation-1 {
  0% {
    transform: translateY(30%);
  }

  10% {
    transform: translateY(11%);
  }

  20% {
    transform: translateY(15%);
  }

  30% {
    transform: translateY(32%);
  }

  40% {
    transform: translateY(50%);
  }

  50% {
    transform: translateY(43%);
  }

  60% {
    transform: translateY(80%);
  }

  70% {
    transform: translateY(50%);
  }

  80% {
    transform: translateY(20%);
  }

  90% {
    transform: translateY(10%);
  }

  100% {
    transform: translateY(100%);
  }
}

.microphone-animated {
  animation: microphone-animation-1 0.8s linear infinite alternate;
  transform: translateY(30%);
}

$cursor-talking-steps: (
  'from': 0.32,
  '18%': 0.41,
  '52%': 0.68,
  '78%': 0.77,
  '100%': 0.62,
);
$cursor-inner-ring-size: 0.625rem; // 10px
$cursor-outer-ring-size: 1.875rem; // 30px

@keyframes cursor-talking-animation-inner {
  @each $step, $offset in $cursor-talking-steps {
    #{$step} {
      padding: calc($offset * $cursor-inner-ring-size);
      border-radius: calc($offset * $cursor-inner-ring-size);
    }
  }
}

@keyframes cursor-talking-animation-outer {
  @each $step, $offset in $cursor-talking-steps {
    #{$step} {
      padding: calc($offset * $cursor-outer-ring-size);
      border-radius: calc($offset * $cursor-outer-ring-size);
    }
  }
}

.cursor-talking {
  /* @apply is a method from tailwind */
  @apply box-content absolute flex items-center justify-center w-full h-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 -z-10;
  padding: $cursor-inner-ring-size;
  border-radius: $cursor-inner-ring-size;
  opacity: 0.6;
  animation: cursor-talking-animation-inner 0.4s linear infinite alternate;
  animation-delay: 300ms;

  &::before {
    @apply box-content absolute w-full h-full;
    content: '';
    background-color: inherit;
    opacity: 0.4;
    padding: $cursor-outer-ring-size;
    border-radius: $cursor-outer-ring-size;
    animation: cursor-talking-animation-outer 0.5s linear infinite alternate;
  }

  &.rounded-full {
    border-radius: 100% !important;

    &::before {
      border-radius: 100% !important;
    }
  }
}

@keyframes loading-fade-out {
  0% {
    opacity: 1;
    max-height: 100%;
  }

  99% {
    opacity: 0;
    max-height: 100%;
  }

  100% {
    opacity: 0;
    max-height: 0;
  }
}

@keyframes loading-fade-in {
  0% {
    opacity: 0;
    max-height: 0;
  }

  1% {
    opacity: 0;
    max-height: 100%;
  }

  100% {
    opacity: 1;
    max-height: 100%;
  }
}

.loading-fade-in {
  animation: loading-fade-in 0.5s ease-in-out forwards;
}

.loading-fade-out {
  animation: loading-fade-out 0.5s ease-in-out forwards;
}

@layer utilities {
  /* Scroll thumb styles */
  .dashboard_scrollbar::-webkit-scrollbar {
    width: 0.5rem;
  }

  .dashboard_scrollbar::-webkit-scrollbar-thumb {
    background: #646464;
    border-radius: 1.5rem;
  }

  .dashboard_scrollbar::-webkit-scrollbar-thumb:active {
    background-color: #575757;
  }

  /* Scroll track styles (Background) */
  .dashboard_scrollbar::-webkit-scrollbar-track {
    background: #3b3b3b;
    border-radius: 1.5rem;
  }

  .dashboard_scrollbar::-webkit-scrollbar-track:hover,
  .dashboard_scrollbar::-webkit-scrollbar-track:active {
    background: #3b3b3b;
  }

  .font-anonymous-pro {
    font-family: 'Anonymous Pro', monospace;
  }

  /**
  Taken from:
  https://github.com/tailwindlabs/tailwindcss/discussions/5675

  Makes it so rounded-full, with overflow-hidden parent works in Safari.
  */
  .fix-safari-overflow {
    -webkit-mask-image: -webkit-radial-gradient(white, black);
  }

  .fix-safari-transitions {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
  }
}

/** This animation goes from -100% to 200% in the X axis */
@keyframes shimmer {
  0% {
    left: -100%;
  }

  100% {
    left: 200%;
  }
}

.shimmer {
  animation: shimmer 1s ease-in-out;
  left: -100%;
}

@keyframes skeleton {
  50% {
    opacity: 0.4;
  }
}

.animate-skeleton {
  animation: skeleton 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

svg {
  stroke-width: 2px;
  flex-shrink: 0;
}

path,
circle,
ellipse,
line,
polygon,
polyline,
rect {
  stroke-width: inherit;
}

.icon-wrapper {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  svg {
    position: absolute;
  }
}

#tooltip-portal {
  position: fixed;
  z-index: 9999;
}

html.todesktop body {
  background: white;
  border: 1px solid #5c5c5c;
  border-radius: 10px;
}

.badge_url {
  padding: 1.25px 7px;
  border-radius: 9px;

  background: #d9d9d9;
  color: black;

  font-size: 13px;

  width: max-content;

  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

/* Scroll thumb styles */
.dashboard_scrollbar::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.dashboard_scrollbar::-webkit-scrollbar-thumb {
  background: rgb(146 146 146 / 30%);
  border-radius: 1.5rem;
  visibility: hidden;
}

.dashboard_scrollbar:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}

.dashboard_scrollbar::-webkit-scrollbar-thumb:active,
.dashboard_scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgb(146 146 146 / 40%);
  visibility: visible;
}

/* Scroll track styles (Background) */
.dashboard_scrollbar::-webkit-scrollbar-track {
  background: rgb(0 0 0 / 7%);
  border-radius: 1.5rem;
  visibility: hidden;
}

.dashboard_scrollbar:hover::-webkit-scrollbar-track {
  visibility: visible;
}

.highlight {
  font-weight: bold;
}
