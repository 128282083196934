.item {
  position: relative;

  display: inline-block;
  width: 100%;

  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  // outline: 1px solid rgba(0, 0, 0, 0.1);
  background: white;
  border-radius: 16px;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02), 0px 4px 25px rgba(0, 0, 0, 0.05);

  &.selected {
    border: 1px solid #1836d3;
    outline: 1px solid #1836d3;
  }
}

.item:not(.item__loading):not(.disableHover) {
  cursor: pointer;

  &:hover,
  &.item--hover {
    // 6px box-shadow around the item (rgba(42, 55, 238, 0.3))
    box-shadow: 0px 0px 0px 6px rgba(42, 55, 238, 0.3);
  }
}

.item__content_wrapper {
  display: flex;
  overflow: hidden;
  border-radius: 15px;

  width: 100%;
  height: 100%;
}

.item__view_mode__grid {
  min-height: 80px;

  .item__content_wrapper {
    flex-direction: column;
  }
}

.item__view_mode__list {
  padding: 10px;

  .item__content_wrapper {
    flex-direction: row;
    max-width: 100%;
    align-items: center;
  }
}

.item__created_at {
  margin-left: auto;

  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  flex-shrink: 0;

  padding-left: 25px;
  padding-right: 5px;

  color: #666;
}

.item__collection {
  svg {
    flex-shrink: 0;
    width: 18px;
    height: 14px;
  }

  z-index: 8;

  position: absolute;
  bottom: -6px;
  right: -6px;

  border-radius: 11px;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba(51, 0, 255, 0.8);

  &:hover {
    background: #3300ff;
  }

  span {
    display: none;
  }
}

.item__type__notepad {
  background: #fffbec;

  border: 1px solid rgba(0, 0, 0, 0.1);
}