.member_bubble {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  font-weight: 500;
  font-size: 11px;
  line-height: 0;

  border-radius: 50%;

  flex-shrink: 0;
}

.avatar {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}
