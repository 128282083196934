.collection_missing {
  display: flex;
  flex-direction: column;
  background: #f4f4f7;
  align-items: center;

  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.collection_missing__header {
  padding: 20px 26px;
  width: 100%;

  > svg {
    width: 30px;
    height: auto;
  }
}

.collection_missing__content {
  margin-top: 195px;
  color: black;
  max-width: 600px;

  h1 {
    font-weight: 800;
    font-size: 65px;
    line-height: 70px;
    margin-top: 0;
    margin-bottom: 40px;
  }

  p {
    opacity: 0.5;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-top: 0;
    margin-bottom: 40px;
  }

  > div {
    display: flex;
    align-items: center;
  }
}
