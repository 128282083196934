.file {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: fit-content;
  max-width: 90%;

  // Double selector, because we want to override the default
  &.file {
    user-select: none;
    * {
      user-select: none;
    }
  }

  gap: 8px;

  &.scaled_down .filename {
    font-size: 14px;
  }
  &.clickable {
    cursor: pointer;

    &:hover {
      .filename,
      .file_icon {
        background: #f1f1f1;
      }
    }

    &:active {
      .filename,
      .file_icon {
        background: #e6e6e6;
      }
    }
  }
}

.file_icon {
  padding: 10px 20px 15px 20px;
  border-radius: 40px;
}

.extension {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  bottom: 10%;
  left: 50%;

  text-transform: uppercase;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  font-weight: 500;
  font-size: 16.4px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.4);

  transform: translateX(-50%);

  &.pdf {
    background: #f15742;
    color: white;
    border-radius: 4.8px;
    max-width: none;
    width: 80%;
    height: 26.8px;
    font-weight: 600;

    display: flex;
    justify-content: center;
    align-items: center;

    bottom: 30%;
    left: -10px;
    transform: translateX(0);
  }
}

.filename {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #666;
  max-width: 100%;

  text-align: center;
  overflow: hidden;

  padding: 10px;
  background: #f8f8f8;
  border-radius: 8px;
}
