// @value is a css import that allows us to use parent's css classes in child components
@value item__view_mode__list, item from "../FdocItem.module.scss";

.item__comments__wrapper {
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 13px;

  // double to make sure the item__comments loses its padding
  .item__comments.item__comments {
    padding: 0;
  }
}

.first_comment {
  display: flex;
  align-items: flex-start;
  gap: 8px;

  color: #666;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  > span {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.item__comments {
  display: flex;
  align-items: center;

  gap: 8px;

  padding: 10px 10px 0 10px;

  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #666;

  > span {
    text-decoration: underline;
  }

  &:last-child {
    padding-bottom: 10px;
  }
}

.item__comments__users {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;

  > div:not(:first-child) {
    margin-right: -3px;
  }

  gap: 2px;
}

.item__comments__total {
  display: flex;
  align-items: center;

  gap: 8px;
}

// parent is in list view
.item__view_mode__list {
  .item__comments {
    max-width: 40%;
    min-width: 115px;
    white-space: nowrap;

    background: #f5f5f5;
    border-radius: 8px;
    max-height: 72px;
    align-items: flex-start;

    padding: 10px;

    flex-direction: column;

    > span {
      text-decoration: none;
      overflow: hidden;
      text-overflow: ellipsis;
      max-height: 32px;
      max-width: 100%;
    }
  }
}
